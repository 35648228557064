.splitWidgetHolder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 24px;
}

.splitWidgetContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    height: 90%;
}

.splitWidgetSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
}

.trailAddButton {
    background-color: #285ff5;
    color: white;
    padding: 8px 16px;
    border-radius: 16px;
    font-weight: 600;
}

.trailDelete {
    width: 18px;
    height: 18px;
}

.paymentTrailRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    text-align: left;
}

.splitGroupList {
    flex: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    overflow-x: scroll;
    width: 100%;
}

.splitExpand {
    position: fixed;
    max-height: 70vh;
    width: 60vw;
    background-color: white;
    top: 15vh;
    right: 20vw;
    border-radius: 20px;
    padding: 24px;
    box-sizing: border-box;
    @media screen and (max-width: 768px){
        width: 90vw;
        max-height: 60vh;
        right: 5vw;
        top: 20vh;
        overflow-y: scroll;
    }
}

.splitSelected {
    border: 5px solid green;
}