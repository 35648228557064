.creationNavBarTop {
    display: flex;
    justify-content: center;
    height: 4px;
    padding: 10px 0;
}

.creationNavBarTopHolder {
    display: flex;
    gap: 5px;
    height: 4px;
}

.navBarPageTab {
    height: 4px;
    width: 77px;
    background-color: #D9D9D9;
    border-radius: 10px;
}

.navBarPageTabSelected {
    background-color: #000000;
}