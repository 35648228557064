.backButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.backIcon {
    height: 20px;
    rotate: 180deg;
    object-fit: contain;
}