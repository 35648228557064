.profilePage {
    padding: 5vh 0;
    flex-direction: column;
    width: 1440px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.currentProfile {
    grid-column: span 7;
}

.pastEventsHolder {
    grid-column: 9 / span 4;
    background-color: #F3F3F3;
    border-radius: 20px;
    padding: 30px;
}

.pastEventsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.profileTopHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profilePicture {
    min-width: 150px;
    height: 150px;
    border-radius: 150px;
    overflow: hidden;
    position: relative;
}

.profilePicture:hover .editPictureText {
    opacity: 1;
}

.usernameHolder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.editPictureText {
    position: absolute;
    bottom: 0;
    width: 150px;
    height: 30%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: 600;
    font-size: 1rem;
    padding: 5px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 0;
    transition: .5s ease;
}

.usernameText {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Wix Madefor Display';
}

.attendenceText {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.profileTitleText {
    font-size: 1rem;
    color: #808080;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-left: 30px;
}

.editIcon {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.hostingSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;
}

.currentHostHolder {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pastEventsTitle {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Wix Madefor Display';
}

.pastHostHolder {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tempButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.SetupPaymentButton {
    background-color: #F9F9F9;
    border-radius: 10px;
    width: 100px;
    color: black;
    font-family: 'Wix Madefor Display';
    font-weight: 600;
    font-size: 10px;
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;
}



@media screen and (max-width: 1440px) {
    .profilePage {
        padding: 5vh 5vw;
    }

    .currentProfile {
        grid-column: span 12;
    }

    .profilePicture {
        min-width: 75px;
        height: 75px;
        border-radius: 75px;
        overflow: hidden;
        position: relative;
    }
}