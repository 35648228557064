.upcomingEvents {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.upcomingEventsHolder {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-left: solid black 4px;
    padding-left: 20px;
    align-items: flex-start;
    padding-bottom: 20px;
}

.upcomingEventsTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: start;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.date-text {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: start;
    background-color: white;
    padding: 8px 12px;
    margin: -8px -12px;
    border-radius: 5px;
    position: sticky;
    top: 90px;
    z-index: 5;
}

.upcomingDayOfWeek {
    font-weight: 400;
    margin-left: 10px;
}

.see-more {
  font-size: 1rem;
  font-weight: 600;
  color: #797979;
  text-align: center;
  cursor: pointer;
  padding-top: 40px;
  border-left: dashed black 4px;
}

.location-autocomplete {
  border: none;
  padding: 0 !important;
  box-sizing: border-box;
}

.location-autocomplete .ant-select-selector {
  width: 100% !important;
  padding: 10px !important;
  margin: none !important;
  background-color: transparent !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  border: none !important;
  color: inherit !important;
}

.location-autocomplete .ant-select-selector:focus-within {
  box-shadow: none !important;
}

.location-autocomplete .ant-select-selection-search-input {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  padding: 0px !important;
  color: #797979 !important;
  height: 100% !important;
}

.location-autocomplete .ant-select-selection-search {
  display: flex;
  margin-top: 4px;
  border-bottom: solid #797979 2px;
}

.search-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}
