.eventAbout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column: 2 / span 5;
    @media screen and (max-width: 768px){
        grid-column: span 12;
    }
}

.eventAboutTitle {
    font-size: 1.5rem;
}

.aboutText {
    font-size: 1.2rem;
    text-align: start;
    margin-top: 10px;
}

.aboutTextPlaceholder {
    font-size: 1.2rem;
    text-align: start;
    margin-top: 10px;
    color: #a0a0a0;
}

.eventDescriptionEditor {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
}

.eventDescriptionTextBox {
    width: 100%;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    min-height: 200px;
    padding: 10px;
    resize: vertical;
    box-sizing: border-box;
}

.descriptionActions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.descriptionActionButton {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.descriptionActionGray {
    background-color: #D9D9D9;
}