.profilePicArea {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AccountIcon {
    max-height: 30px;
    min-width: 30px;
    border-radius: 30px;
}

.initialOverlay {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: 500;
}