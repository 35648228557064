.responseDashboard {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    background-color: #F1F1F1;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 25px;
    gap: 20px;
}

.ticketDashboard {
    background-color: #EBEBEB;
    display: flex;
    min-height: 90vh;
    padding: 5vh 8vw;
    flex-direction: row;
    align-items: flex-start;
}

.eventDashboardName {
    font-family: 'Wix Madefor Display';
    font-size: 36px;
    font-weight: 600;
    text-align: start;
    margin-bottom: 30px;
}

.widgetHolder {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
}

.widget1 {
    height: 250px;
    grid-column: span 6;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-sizing: border-box;
}

.widget2 {
    height: 250px;
    grid-column: span 12;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-sizing: border-box;
}

.widget3 {
    height: 250px;
    grid-column: span 12;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-sizing: border-box;
}

.widget0 {
    height: 250px;
    grid-column: span 12;
    border-radius: 20px;
    padding: 20px;
    background-color: white;
    box-sizing: border-box;
}

.widget2y {
    height: 520px;
}

.widgetPadding0 {
    padding: 0;
}

.widgetBlack {
    background-color: black;
}

.widgetGray {
    background-color: #E7E7E7;
}