.guestListView {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column: 8 / span 4;
    @media screen and (max-width: 768px){
        grid-column: span 12;
    }
}

.guestListTitle {
    font-size: 1.5rem;
}

.guestListContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 24px;
    @media screen and (max-width: 768px){
        gap: 48px;
    }
}

.guestListPreview {
    flex: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.guestListPreviewSmall {
    flex: 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
}

.goingSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.guestlistExpandView {
    position: fixed;
    overflow-y: hidden !important;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 10;
}

.guestlistExpand {
    position: fixed;
    max-height: 50vh;
    width: 60vw;
    background-color: white;
    top: 25vh;
    right: 20vw;
    border-radius: 20px;
    padding: 24px;
    box-sizing: border-box;
    @media screen and (max-width: 768px){
        width: 90vw;
        max-height: 60vh;
        right: 5vw;
        top: 20vh;
    }
}

.guestlistRows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
}

.guestlistPhoto {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.guestlistText {
    font-family: 'Source Sans 3';
    font-size: 1rem;
    text-align: center;
    color: #767676;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.goingBigText {
    font-family: 'Wix Madefor Display';
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
}