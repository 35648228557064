.Header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 60px;
    width: 100%;
    padding: 0 8vw;
    background-color: white;
    z-index: 10;
    position: fixed;
    box-sizing: border-box;
}

.LogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Logo {
    width: 70px;
    object-fit: contain;
}

.AccountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    cursor: pointer;
}

.AccountContainerInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.AccountActionText {
    font-family: 'Wix Madefor Display';
    font-size: 1rem;
    font-weight: 600;
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 5px;
}

.AccountText {
    font-size: 1.2rem;
}

.SubNavText {
    font-size: 1.1rem;
    font-weight: 500;
    color: #797979;
    cursor: pointer;
}

.Menu {
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    top: 60px;
    right: 8vw;
    position: absolute;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: 24px;
    box-sizing: border-box;
    gap: 10px
}