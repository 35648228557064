.SearchBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: #F1F1F1;
    border-radius: 10px;
    padding: 20px;
    margin-top: 3%;
    box-sizing: border-box;
}

.SearchInput {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    font-family: 'Source Sans 3';
    font-size: 1.4rem;
}

.SearchInput::placeholder {
    color: #CBCBCB;
}

.SearchIcon {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    margin-left: 5px;
}