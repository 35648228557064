.eventCreatePage {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.creationPages {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow-y: hidden;
    justify-content: center;
    gap: 3%;
}