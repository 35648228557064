/* Preview */

.calendar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    width: 100%;
    border-radius: 20px;
    position: relative;
}

.react-calendar {
    background-color: white;
    border: none;
    border-radius: 20px;
    padding: 35px;
    width: 70%;
    max-width: 100%;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 700px;
}

/* .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
} */

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar__navigation__label {
    font-size: 1.1em;
    font-weight: 500;
    height: 50px;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    background: none;
}

.react-calendar__navigation__arrow {
    font-size: 2.5em;
    color: #3f3f3f;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

abbr {
    text-decoration: none;
}

.selected-date {
    background-color: #686868 !important;
    color: white;
    border-radius: 10px;
}

.selected-date-start {
    background-color: #686868 !important;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.selected-date-end {
    background-color: #686868 !important;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.selected-date-middle {
    background-color: #686868 !important;
    color: white;
    border-radius: 0px !important;
}

.lightRange {
    background: #e6e6e6 !important;
    color: black;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    font: inherit;
    font-size: 1.1em;
    color: #9c9d9f;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 1.1em;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #9c9d9f;
}

.react-calendar__tile {
    max-width: 100%;
    aspect-ratio: 1/1;
    padding: 0 6.6667px;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 1.1em;
    font-weight: 500;
}

.react-calendar__tile--now {
    border-radius: 10px;
    border: #d4d4d4 1px solid !important;
}

/* .react-calendar__tile--active {
    background: #006edc;
    color: white;
} */

.react-calendar--selectRange {
    background-color: #e6e6e6;
}