.eventTypeCreateButtonOptions {
    margin-top: 30px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.eventTypeSlideComponent {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 10px;
}

.eventTypeSlideComponentOffset {
    margin-top: -100px;
}

.eventTypePadding {
    padding: 0 !important;
    gap: 10px;
    justify-content: center;
}