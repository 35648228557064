.searchPage {
    display: flex;
    padding: 5vh 5vw;
    flex-direction: column;
    align-items: flex-start;
}

.searchPageSearchBox {
    width: 50vw;
    margin-bottom: 50px;
}

.searchPageDefaultText {
    font-family: 'Wix Madefor Display';
    font-size: 24px;
    font-weight: 600;
    color: #808080;
}

.searchPageSearchText {
    color: #000000;
}

.resultBox {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.backToTopButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
}

.backToTopText {
    font-family: 'Source Sans 3';
    font-size: 24px;
}

.upArrow {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-left: 10px;
    rotate: 270deg;
}