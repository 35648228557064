.transactionCheckout {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.cartSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 100px 35px;
}

.transactionTitle {
  font-family: "Wix Madefor Display";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.6px;
  margin-bottom: 30px;
}

.transactionInstructionText {
  font-family: "Source Sans 3";
  font-size: 18px;
  color: #797979;
  letter-spacing: -0.6px;
}

.transactionEventTitle {
  font-family: "Wix Madefor Display";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.6px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tierEntrySection {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.tierEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  height: 30px;
}

.tierTitle {
  font-family: "Source Sans 3";
  font-size: 20px;
}

.tierQuantity {
  font-family: "Source Sans 3";
  font-size: 20px;
  letter-spacing: -0.6px;
}

.priceSection {
    background-color: #F1F1F1;
    box-sizing: border-box;
    padding: 35px;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.pricingRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.subtotalText {
    font-family: "Wix Madefor Display";
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #797979;
}

.totalText {
    font-family: "Wix Madefor Display";
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.6px;
    color: #000000;
}

.checkoutBack {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}