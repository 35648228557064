.createInspiration {
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: #F9F9F9;
    border-radius: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 90px;
    padding: 28px;
}

.createInspirationTitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: start;
    margin-bottom: 16px;
}

.createInspirationSubtitle {
    font-size: 1.1rem;
    text-align: start;
    margin-bottom: 24px;
}

.createInspirationH4 {
    font-size: 1.1rem;
    color: #797979;
    text-align: start;
    margin-bottom: 12px;
}

.inspirationHolder {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 8px;
}

.inspirationTile {
    padding: 12px 12px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #484848;
    border-radius: 10px;
    font-size: 1.2rem;
    /* cursor: pointer; */
}