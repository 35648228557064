.transactionScreen {
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.transactionSelection {
  display: flex;
  flex: 2;
}