.noConfirmationSelection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
}

.noConfirmationIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer;
}

.confirmationSubtext {
    font-size: 1.25rem;
}

.confirmationSection {
    border: #D4D4D4 1px solid;
    height: 50%;
    width: 100%;
    margin-top: 30px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 30px;
    box-sizing: border-box;
    gap: 5%;
}

.confirmationImageSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.confirmationInfoSection {
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: flex-start;
}

.confirmationImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.confirmationTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.confirmationTldr {
    font-size: 1.25rem;
}

.confirmationTiers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
}