.mapHolder {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.gm-style iframe+div {
    border-radius: 5px;
    border-color: black !important;
}

.eventMarker {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.pinImage {
    width: 40px;
    height: 40px;
    margin-left: -40px;
    margin-top: -40px;
    object-fit: contain;
}