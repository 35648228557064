.landingPage {
    display: flex;
    padding: 5vh 5vw;
    flex-direction: column;
    max-width: 1440px;
}

.landingHeader {
    margin: 0 8vw;
    margin-top: 8vh;
}

.landingTextSection {
    display: flex;
    flex-direction: column;
    grid-column: span 6;
}

.landingSearchSection {
    display: flex;
    grid-column: 8 / span 5;
    flex-direction: column;
    align-items: flex-start;
}

.landingTitle {
    font-family: 'Wix Madefor Display';
    font-size: 3.7rem;
    font-weight: 600;
    letter-spacing: -2%;
    margin-bottom: 20px;
    text-align: start;
}

.landingHeaderSubtitle {
    font-family: 'Source Sans 3';
    font-size: 1.5rem;
    text-align: start;
}

.infoSection {
    height: 300px;
    padding: 0 8vw;
    margin-top: 114px;
    margin-bottom: 100px;
}

.landingPageUpcoming {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    flex-direction: row;
}

.landingPageHalfSection {
    display: flex;
    grid-column: span 6;
}

.landingPageHalfSection2 {
    display: flex;
    grid-column: 8 / span 5;
}

@media all and (max-width: 768px) {
    .landingPageHalfSection {
        grid-column: span 12;
    }
}