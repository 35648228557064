.galleryImageLarge {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.galleryImageSmallContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
}

.galleryImageSmall {
    max-width: 50%;
    height: 100%;
    flex: 1;
    object-fit: cover;
    background-position: center;
}

.relativeView {
    position: relative;
}

.galleryOverlay {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.30);
    display: flex;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: flex-start;
    color: white;
    font-size: 20px;
    opacity: 0;
    transition: opacity 250ms ease;
}

.galleryOverlay:hover {
    opacity: 1;
    cursor: pointer;
}

.expandIcon {
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.addGalleryIcon {
    height: 16px;
    width: 16px;
}

.addGalleryImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: black;
    border-radius: 20px;
    padding: 4px 12px;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 250ms ease;
}

.addGalleryImage:hover {
    transform: translateY(-3px);
}

.photoExpandView {
    position: fixed;
    overflow-y: hidden !important;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.photoExpand {
    position: fixed;
    max-height: 80vh;
    width: 60vw;
    background-color: white;
    top: 10vh;
    right: 20vw;
    border-radius: 20px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: 768px){
        width: 90vw;
        max-height: 60vh;
        right: 5vw;
        top: 20vh;
    }
}

.galleryImageSmallContainerExpand {
    display: flex;
    width: 50%;
    aspect-ratio: 1;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
}