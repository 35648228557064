.confirmationTag {
    background-color: #F1F1F1;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.confirmationTagIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin: 5px;
}

.confirmationTagText {
    font-size: 1rem;
    margin: 5px;
    color: #797979;
}