.signupPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 48px;
    gap: 24px;
}

.signupNavigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.signupLogin {
    font-size: 1rem;
    color: #797979;
    cursor: pointer;
}

.signupForm {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
    gap: 28px;
}

.signupRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1.5px solid #787878;
}

.signupInput {
    width: 100%;
    border: none;
    height: 40px;
    font-size: 1.2rem;
    box-sizing: border-box;
}

.passwordRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.showPassword img {
    object-fit: contain;
    width: 20px;
    cursor: pointer;
}

.signupAction {
    background-color: #000000;
    color: #FFFFFF;
    padding: 21px 27px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 24px 0px;
}

.signupInput::placeholder {
    color: #9d9d9d;
    font-size: 1rem;
}