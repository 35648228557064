.EventTileText {
    color: white;
    font-size: 1.25rem;
}

:root {
    --slide-width: 21vw;
    --slide-height: 500px;
    --slide-height2: 510px;
    --no-of-slides: 10;
}

@keyframes tileScroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(var(--slide-width) * var(--no-of-slides) * -1));
    }
}

@keyframes tileScrollY {
    0% {
        transform: translateY(0);
    }

    100% {
        /* slide height needs to account for the tile AND gap */
        transform: translateY(calc(var(--slide-height2) * var(--no-of-slides) * -1));
    }
}

.EventTile {
    aspect-ratio: 0.7;
    flex: 0 0 var(--slide-width);
    max-width: var(--slide-width);
    min-width: var(--slide-width);
    border-radius: 10px;
    animation: tileScroll 90s linear infinite;
    animation-direction: forwards;
    padding: 0 0.5%;
    box-sizing: border-box;
    cursor: pointer;
}

.EventTileY {
    aspect-ratio: 0.7;
    flex: 0 0 var(--slide-height);
    max-height: var(--slide-height);
    min-height: var(--slide-height);
    border-radius: 10px;
    animation: tileScrollY 90s linear infinite;
    animation-direction: forwards;
    box-sizing: border-box;
    cursor: pointer;
}

.EventTileYFast {
    animation: tileScrollY 60s linear infinite;
}

.EventContent {
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.EventContentY {
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.ImageMask {
    background-color: black;
    width: 95.2%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    border-radius: 10px;
}

.ImageMaskY {
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    border-radius: 10px;
}

.EventTileInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
    z-index: 2;
}

.EventTileInfoTitle {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    max-lines: 2;
    text-align: start;
}

.EventTileInfoHost {
    opacity: 0.7;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}