.newsletterTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.newsletterGreeting {
    font-size: 1.5rem;
    background-color: #F9F9F9;
    padding: 5px 20px;
    border-radius: 10px;
    color: #797979;
    font-weight: 500;
    letter-spacing: -1px;
}

.newsletterText {
    font-weight: 500;
    font-size: 3.25rem;
    text-align: start;
    line-height: 1.7;
    letter-spacing: -1px;
}

@media screen and (max-width: 768px) {
    .newsletterText {
        font-size: 1.5rem;
    }
}

.newsletterNumber {
    color: white;
    border-radius: 20px;
    margin: 0px 20px;
    background-image: url('https://source.unsplash.com/random/?event,gathering');
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 768px){
        margin: 0px 10px;
    }
}

.newsletterImg2 {
    background-image: url('https://source.unsplash.com/random/?event,picnic');
}

.newsletterContent {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    width: 100%;
    padding: 0px 20px;
}
