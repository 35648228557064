.TransactionBackButton {
  display: inline-flex;
  padding: 21px 27px;
  gap: 10px;
  background-color: #F1F1F1;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TransactionBackText {
  font-family: "Wix Madefor Display";
  font-size: 18px;
  font-weight: 600;
  color: #797979;
}