.invoiceWidgetEntry {
    font-family: 'Source Sans 3';
    font-size: 1.5rem;
    text-align: start;
}

.invoiceWidgetMoreEntry {
    font-family: 'Source Sans 3';
    font-size: 1rem;
    color: #767676;
    text-align: start;
}

.invoiceWidgetView {
    font-family: 'Source Sans 3';
    font-size: 1rem;
    color: #767676;
    text-align: start;
    cursor: pointer;
}