.eventTypeButton {
    background-color: #F1F1F1;
    padding: 4% 5%;
    border-radius: 10px;
}

.eventTypeButtonSelected {
    background-color: #DADADA;
}

.eventTypeButtonTitle {
    font-size: 1.25rem;
    font-weight: 600;
}

.eventTypeButtonDescription {
    font-size: 1rem;
}