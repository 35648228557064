.NameInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
}

.EmailInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.ColLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 10px;
}

.InputName {
    margin-right: 10px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}

.InputEmail {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}
