.CheckoutButton {
  display: flex;
  padding: 21px 27px;
  margin-top: 30px;
  gap: 10px;
  background-color: #000000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.CheckoutText {
  font-family: "Wix Madefor Display";
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}