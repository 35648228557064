.eventCreationTicketOptions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 80%;
}

.fundingCurrency {
    width: 50%;
    text-align: center;
}

.fundingGoalInputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 40%;
}

.tierOption {
    display: flex;
    flex-direction: column;
    border: #D4D4D4 1px solid;
    width: 100px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-weight: 600;
    cursor: pointer;
}

.tierOptionTitle {
    font-size: 1rem;
}

.tierOptionSubtitle {
    font-size: 0.75rem;
}

.addTierButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 120px;
    gap: 8px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 0px;
}

.addTierImg {
    width: 30px;
    height: 30px;
}

.tierSelected {
    background-color: black;
    color: white;
}

.tierBoxPreview {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F1F1;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.tierBox {
    display: flex;
    height: 90%;
    width: 50%;
    flex-direction: column;
}

.tierDeletionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.tierName {
    border: #D4D4D4 1px solid;
    padding: 10px 20px;
    border-radius: 3cm;
}

.tierEditSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    height: 100%;
    position: relative;
}

.tierEditPrice {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    padding: 15px;
    border-radius: 20px;
    position: absolute;
    top: 0px;
    width: 60%;
    height: 100px;
    border: #F1F1F1 5px solid;
    box-sizing: border-box;
}

.tierEditPriceInner {
    background-color: #3D3D3D;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tierEditPriceInput {
    width: 100%;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    color: #C6C6C6;
    text-align: center;
    font-size: 1.5rem;
}

.tierEditInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    height: 90%;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 5%;
    box-sizing: border-box;
    gap: 10px;
    margin-top: 50px;
}

.tierTitleInput {
    width: 70%;
    border-radius: 5px;
    border: none;
    background-color: #F1F1F1;
    text-align: center;
    font-size: 1.5rem;
    padding: 5px 20px;
    box-sizing: border-box;
}

.tierDescInput {
    width: 90%;
    height: 100%;
    border-radius: 5px;
    border: none;
    background-color: #F1F1F1;
    text-align: flex-start;
    font-size: 1rem;
    padding: 20px;
    box-sizing: border-box;
    resize: none;
    display: list-item;
}