.eventOverview {
  display: flex;
  grid-column: 2 / span 5;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
}

.eventOverviewBasic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.eventOverviewSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.eventOverviewAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
}

.eventOverviewSeeMore {
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 5%;
}

.eventOverviewTitle {
  font-family: "Wix Madefor Display";
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -1.2px;
  text-align: start;
  margin-bottom: 14px;
  margin-top: 24px;
}

.eventOverviewRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 24px;
}

.eventOverviewTldr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 1.2rem;
  text-align: left;
}

.eventOverviewIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  object-fit: contain;
}

.eventOverviewText {
  font-family: "Source Sans 3";
  font-size: 20px;
  text-align: start;
  color: #808080;
}

.eventDetailMapHolder {
  width: 80%;
  height: 180px;
  margin-top: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}