.bringingLists {
    justify-content: space-between;
    min-height: 300px;
}

.bringWidgetHolder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.noContributionText {
    color: #767676;
}

.noContributionArea {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.currentlyBringingSection {
    padding: 12px 0px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.currentlyBringing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 0px;
}

.currentlyBringingLimited {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 0px;
    overflow: hidden;
    max-height: 105px;
}

.currentlyRequested {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 12px 0px;
}

.moreBringingText {
    color: #767676;
    cursor: pointer;
}

.myBringingSection {
    padding: 12px 0px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.requestedSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.requestedBringing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bringingTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 40px;
    height: 50px;
    padding: 12px 12px 12px 16px;
    gap: 10px;
    box-sizing: border-box;
}

.deleteBringingIcon {
    width: 16px;
    height: 16px;
}

.bringingTagText {
    color: white;
    font-weight: 600;
}

.requestItemTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #808080;
    font-weight: 600;
    border-radius: 40px;
    gap: 10px;
    height: 50px;
    padding: 12px 24px;
    box-sizing: border-box;
    border: 2px solid #808080;
}

.requestTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    gap: 10px;
    height: 50px;
    padding: 12px 24px;
    box-sizing: border-box;
    background-color: black;
}

.requestItemButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #797979;
    font-weight: 600;
    border-radius: 5px;
    gap: 10px;
    height: 50px;
    width: 150px;
    padding: 12px 24px;
    box-sizing: border-box;
    cursor: pointer;
}

.addItemButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    gap: 10px;
    height: 50px;
    width: 150px;
    padding: 12px 24px;
    box-sizing: border-box;
    background-color: black;
    cursor: pointer;
}

.bringExpand {
    position: fixed;
    max-height: 50vh;
    width: 60vw;
    background-color: white;
    top: 25vh;
    right: 20vw;
    border-radius: 20px;
    padding: 24px;
    box-sizing: border-box;
    @media screen and (max-width: 768px){
        width: 90vw;
        max-height: 60vh;
        right: 5vw;
        top: 20vh;
        overflow-y: scroll;
    }
}

.expandContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.expandBringContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
}

.bringContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    box-sizing: border-box;
    height: 100%;
    width: 45%;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 768px){
        width: 100%;
    }
}

.requestInputBox {
    width: 100%;
    padding-left: 12px;
    height: 50px;
    font-size: 0.875rem;
}

.requestInputBox::placeholder {
    color: #C6C6C6;
}

.confirmBringButton {
    background-color: black;
    border-radius: 40px;
    padding: 12px 24px;
    color: white;
    cursor: pointer;
    font-weight: 600;
}

.verticalDividerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    gap: 10px;
}

.verticalDividerHalf {
    background-color: #787878;
    width: 2px;
    height: 35%;
}