.editWidgetBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 10;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editWidgetModal {
    width: 50vw;
    height: 75vh;
    background-color: white;
    border-radius: 10px;
    padding: 50px;
}

.editWidgetTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.editWidgetTitle {
    font-family: 'Wix Madefor Display';
    font-size: 32px;
    font-weight: 600;
    text-align: start;
}

.editWidgetDesc {
    font-family: 'Source Sans 3';
    font-size: 20px;
    text-align: start;
    margin-top: 20px;
}

.editWidgetMainContent {
    display: flex;
    flex-direction: column;
}

.editWidgetContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    justify-content: space-between;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.editWidgetContent::-webkit-scrollbar {
    display: none;
}

.editWidgetOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    gap: 30px;
    flex-wrap: wrap;
    height: 100%;
    cursor: pointer;
}

.widgetOption {
    background-color: #F1F1F1;
    border-radius: 10px;
    display: flex;
    flex: calc(50% - 15px);
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 20px;
    box-sizing: border-box;
    justify-content: space-between;
}

.widgetOptionTitle {
    font-family: 'Wix Madefor Display';
    font-size: 22px;
    font-weight: 600;
    text-align: start;
}

.widgetOptionDesc {
    font-family: 'Wix Madefor Display';
    font-size: 18px;
    color: #666666;
    text-align: start;
}

.widgetOptionButton {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.widgetOptionButtonText {
    font-family: 'Source Sans 3';
    font-size: 18px;
    font-weight: 600;
    text-align: start;
}

.widgetOptionButtonImg {
    width: 18px;
    height: 18px;
}

.saveAction {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    position: sticky;
    bottom: 0px;
    gap: 10px;
}