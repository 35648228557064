.connectionsList {
    position: fixed;
    height: 80vh;
    width: 50vw;
    background-color: white;
    top: 10vh;
    right: 25vw;
    border-radius: 20px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @media screen and (max-width: 768px) {
        width: 90vw;
        max-height: 60vh;
        right: 5vw;
        top: 20vh;
        overflow-y: scroll;
    }
}

.connectionsList::-webkit-scrollbar {
    display: none;
}

.connection {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.connectionUser {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.connectionName {
    font-size: 1rem;
    font-weight: 600;
    color: #797979;
}

.connectionLastSeen {
    color: #797979;
    font-size: 0.9rem;
    font-weight: 400;
}