.verticalCard {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
    background-color: #F9F9F9;
    border-radius: 20px;
    cursor: pointer;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.verticalCardEventContent {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 20px;
    gap: 10px;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
}

.verticalCardImage {
    object-fit: cover;
    width: 100%;
    border-radius: 20px;
    height: 380px;
}

.verticalCardTldr {
    font-size: 1rem;
    font-weight: 500;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.event-date {
    font-size: 0.9rem;
    color: #797979;
}

.funding-status-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.funding-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.funding-number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: white;
}

.fundingCircle {
    font-weight: 600;
}

.fundingCircleInnerBlack {
    background-color: rgba(0, 0, 0, 0.4);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: absolute;
}

.fundingCircleInner {
    background-color: rgba(255, 255, 255, 0.4);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    position: absolute;
}