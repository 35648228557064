.EventTileOuter {
    border-radius: 10px;
    cursor: pointer;
    width: 24%;
    height: 600px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.EventContentOuter {
    border-radius: 10px;
    min-height: 500px;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.EventTileOuterInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0;
}

.EventTileOuterInfoLocation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -20px;
    margin-bottom: 30px;
}

.EventTileOuterInfoTitleBlack {
    font-family: 'Source Sans 3';
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    margin-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
}

.EventTileOuterInfoHost {
    font-family: 'Source Sans 3';
    font-size: 1.5rem;
    color: black;
    opacity: 0.7;
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}