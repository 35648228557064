.transactionTitleSection {
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
}

.transactionPageTitle {
  font-family: "Wix Madefor Display";
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.6px;
  margin-bottom: 20px;
}

.transactionPageSubtitle {
  font-family: "Source Sans 3";
  font-size: 18px;
  color: #797979;
}