.host-details {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #797979;
}

.attendee-details {
    display: flex;
    align-items: center;
    gap: 4px;
}

.host-image {
    margin-left: -2px;
}

.host-image, .attendee-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px #F9F9F9;
}

.attendees {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.attendee-image {
    display: inline-block;
    margin-left: -16px;
}