.myEventsPage {
    display: flex;
    flex-direction: column;
    padding: 5vh 5vw;
    width: 1440px;
    align-items: flex-start;
}

.myEventsTitle {
    font-weight: 600;
    font-size: 2rem;
}

.myEventsSidecar {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15vh;
    height: 50vh;
}

.sidecarSelection {
    font-family: 'Wix Madefor Display';
    font-size: 18px;
    font-weight: 600;
    color: #797979;
    padding: 20px 30px;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
    box-sizing: border-box;
    text-align: start;
}

.sidecarSelectionSelected {
    font-family: 'Wix Madefor Display';
    font-size: 18px;
    font-weight: 600;
    color: #797979;
    padding: 20px 30px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #F1F1F1;
    width: 80%;
    box-sizing: border-box;
    text-align: start;
}

.myEventsMainContent {
    display: flex;
    flex: 6;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;
}

.myEventsTitle {
    font-family: 'Wix Madefor Display';
    font-size: 36px;
    font-weight: 600;
}

.myEventsHosterSection {
    display: flex;
    flex-direction: row;
    margin: 50px 0;
    gap: 30px;
}

.myEventsHoster {
    font-family: 'Wix Madefor Display';
    font-size: 20px;
    font-weight: 600;
    color: #BEBEBE;
    padding: 10px;
    cursor: pointer;
}

.myEventsHosterSelected {
    font-family: 'Wix Madefor Display';
    font-size: 20px;
    font-weight: 600;
    color: #797979;
    border-bottom: 2px solid #000000;
    padding: 10px;
    cursor: pointer;
}

.myEventsBox {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: flex-start;
    row-gap: 50px;
    margin-top: 30px;
}

@media screen and (max-width: 768px) {
    .myEventsBox {
        grid-template-columns: repeat(1, 1fr);
    }
}