.liveDashboardWidget {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 20px;
    cursor: pointer;
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
}