.editEventButtonHolder {
    padding: 15px 30px;
    border-radius: 40px;
    font-family: 'Wix MadeFor Display';
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
    flex-direction: row;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}