.creationNavBarBottom {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-between;
    width: 100%;
}

.creationNavBarBackArea {
    display: flex;
}