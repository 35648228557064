.eventImageSpace {
  display: flex;
  grid-column: 8 / span 4;
  gap: 20px;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    height: 50vh;
    margin-bottom: 30px;
  }
}


.eventImage {
  display: flex;
  aspect-ratio: 0.7;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    aspect-ratio: 1;
    justify-content: flex-start;
  }
}