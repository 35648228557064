.pollAnswerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.pollAddAnswerImg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.pollDeleteAnswerImg {
    width: 14px;
    height: 14px;
    cursor: pointer;
    object-fit: contain;
}

.pollView {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    flex: 1;
}

.pollOption {
    background-color: #C4C4C4;
    border-radius: 5px;
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 100px;
}

.pollQuestion {
    font-family: 'Source Sans 3';
    font-size: 16px;
    font-weight: 600;
    color: white;
    z-index: 3;
}

.pollResult {
    background-color: black;
    height: 100%;
    position: absolute;
    left: 0px;
    border-radius: 5px;
}

.editWidgetAnswerHolder {
    width: 40%;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #F1F1F1;
    padding: 10px 20px;
    font-family: 'Source Sans 3';
    font-size: 1rem;
    text-align: start;
}

.answeredPoll {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 92%;
    position: absolute;
}