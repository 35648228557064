.eventSplash {
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  min-height: 840px;
  max-width: 1440px;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  box-sizing: border-box;
}

.eventSplashMobile {
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.eventGridContent {
  align-items: center;
  justify-content: center;
  max-height: 700px;
  max-width: 1440px;
  width: 100%;
  padding-bottom: 5%;
  box-sizing: border-box;
}

.eventDetailPage {
  overflow-y: scroll;
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1440px;
  @media screen and (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.eventDetails {
  display: flex;
  flex-direction: row;
  padding: 0 8vw;
  margin-bottom: 70px;
}

.eventDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 100px;
}

.getTicketAction {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: 50px 8vw;
}
