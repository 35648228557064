.roughlyWhen {
  display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 80%;
    max-width: 470px;
}

.whereTags {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 75%;
}

.whereTagsWeekend {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 24px;
}

.whereTag {
  border: #d4d4d4 1px solid;
  color: #3f3f3f;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 30px;
  transition: all 250ms;
}

.whereTagSelected {
  color: white;
  background-color: black;
  border: black 1px solid
}

.whereHiddenText {
  color: #aaaaaa;
}