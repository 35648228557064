.forYou {
    margin-top: 30px;
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.forYouTitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: start;
    margin-bottom: 24px;
}

.forYouHolder {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: flex-start;
    row-gap: 50px;
}

.forYouMobile {
    grid-template-columns: repeat(1, 1fr);
}