.priceTierSection {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  padding-top: 10%;
  padding-bottom: 10%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.priceTierSection::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.priceTierTitle {
  font-family: "Wix Madefor Display";
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.6px;
  margin-bottom: 30px;
}

.priceTierEntrySection {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 1%;
  row-gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.priceTierInfoSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.toggleIcons {
  height: 4vh;
  object-fit: contain;
  cursor: pointer;
}

.priceTierToggle {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}