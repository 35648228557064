.tierLight {
  height: 50vh;
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border: #f1f1f1 solid 2px;
  padding: 20px;
  box-sizing: border-box;
}

.priceTierItemTitleLight {
  font-family: "Source Sans 3";
  font-size: 1rem;
  margin-bottom: 20px;
  color: #797979;
}

.priceTierCostLight {
  font-family: "Wix Madefor Display";
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.6px;
  color: #000000;
}

.tierDividerLight {
  border: 1px solid #f1f1f1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.priceTierPerksLight {
  font-family: "Source Sans 3";
  font-size: 1rem;
  color: #848484;
  margin-bottom: 12px;
  text-align: left;
}

.toggleTextLight {
  font-family: "Wix Madefor Display";
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.6px;
  color: #000000;
}
