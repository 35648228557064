.whereCheckIcon {
    height: 28px;
    width: 28px;
}

.whereGrayText {
    color: #AAAAAA !important;
}

.whereInput {
    width: 90%;
    background-color: #F1F1F1;
    border: none;
    height: 50px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 1rem;
    margin: 10px 0;
}

.whereInput::placeholder {
    color: #C6C6C6;
}

.roughlyWhere {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 80%;
}