.eventFunding {
  padding-right: 10%;
  margin-top: 34px;
  margin-bottom: 24px;
}

.fundingTitle {
  text-align: start;
}

.fundingBarSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.fundingBar {
  display: flex;
  border: #000000 2px solid;
  border-radius: 50px;
  height: 11px;
  width: 100%;
}

.fundingBarFilled {
  background-color: #000000;
  border-radius: 50px;
  height: 11px;
}

.fundingValue {
  font-family: "Wix Madefor Display";
  font-size: 22px;
  font-weight: 600;
  color: #767676;
}

.fundingStats {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.fundingValueBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.fundingValueTitle {
  font-family: "WiX Madefor Display";
  font-weight: 600;
  font-size: 1.9rem;
  color: #000000;
}

.fundingText {
  font-family: "Source Sans 3";
  font-size: 1.2rem;
  text-align: start;
  color: #767676;
}
