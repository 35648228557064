.horizontalCard {
    display: flex;
    flex-direction: row;
    grid-column: span 1;
    height: 200px;
    width: 100%;
    background-color: #F9F9F9;
    border-radius: 20px;
    cursor: pointer;
    justify-content: space-between;
    overflow: hidden;
}

.horizontalCardEventContent {
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
}

.horizontalCardImageHolder {
    margin: 16px;
}

.horizontalCardImage {
    object-fit: cover;
    border-radius: 10px;
    width: 150px;
    height: 100%;
    z-index: 0;
}

.horizontalCardTldr {
    font-size: 1rem;
    font-weight: 500;
    text-align: start;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.event-date {
    font-size: 0.9rem;
    color: #797979;
}

.event-description {
    text-align: start;
}

.event-tags {
    display: flex;
    gap: 8px;
}

.event-tag {
    font-size: 0.9rem;
    padding: 8px 12px;
    border-radius: 20px;
    color: #676767;
    background-color: white;
}

.open {
    width: 48px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventArrowImage {
    width: 24px;
    height: 24px;
    object-fit: contain;
}