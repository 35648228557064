.eventWhatQuestionIcon {
    background-color: #F1F1F1;
    color: #C6C6C6;
    width: 28px;
    height: 28px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    border-radius: 28px;
}

.eventWhatQuestionIconDone {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 28px;
}

.eventWhatImage {
    width: 150px;
    height: 150px;
    background-color: #F1F1F1;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.eventCreationDone {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.eventPreviewBacking {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: #F1F1F1;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.previewBox {
    width: 85%;
    height: 85%;
    background-color: white;
    border-top-left-radius: 40px;
    padding: 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
}

.chosenImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.creationImagePreviewBox {
    height: 25vh;
    background-color: #F1F1F1;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 30px;
}

.creationImagePreview {
    border-radius: 5px;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.creationNamePreview {
    margin-top: 20px;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
}

.creationTLDRPreview {
    text-align: left;
    margin-top: 10px;
}

.nameShadowBox {
    margin-top: 20px;
    height: 10%;
    width: 70%;
    border-radius: 5px;
    background-color: #F1F1F1;
}

.tldrShadowBox {
    height: 20%;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #F1F1F1;
}

.emptyImageIcon {
    width: 50%;
    height: 50%;
    object-fit: contain;
}