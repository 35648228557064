.App {
  text-align: center;
}

.AppContent {
  padding-top: 60px;
  height: calc(100vh - 60px);
}

.AppPadding {
  padding: 0 3vw;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}

.clickable {
  cursor: pointer;
}

.clickableButton {
  cursor: pointer;
  padding: 12px;
  border-radius: 10px;
}

.centerPage {
  display: flex;
  justify-content: center;
}

.fitCover {
  object-fit: cover;
}

.gap6 {
  gap: 6px;
}

.gap12 {
  gap: 12px;
}

.gap24 {
  gap: 24px;
}

.invisiScroll {
  overflow-y: scroll;
}

.invisiScroll::-webkit-scrollbar {
  display: none;
}

.inputBox {
  border-radius: 5px;
  background-color: #F1F1F1;
  border: none;
  padding: 8px;
  box-sizing: border-box;
}

.rowFlexSimple {
  display: flex;
  flex-direction: row;
}

.rowFlex {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.space-between {
  justify-content: space-between;
}

.align-flex-start {
  align-items: flex-start;
}

.align-flex-end {
  align-items: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.width100 {
  width: 100% !important;
}

.popupBackground {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 10;
}

/* Image Icons */

.icon20 {
  width: 20px;
  height: 20px;
}

/* Text */

.gray80 {
  color: #808080;
}

.gray78 {
  color: #787878;
}

.semibold {
  font-weight: 600;
}

.fs14 {
  font-size: 0.875rem;
}

.fs18 {
  font-size: 1rem;
}

.fs20 {
  font-size: 1.25rem;
}

.fs32 {
  font-size: 2rem;
}

.white {
  color: white;
}

.blue {
  color: #285ff5;
}

/* Apps.js Styling */

.appStoreModal {
  position: fixed;
  top: 15%;
  bottom: 15%;
  left: 5%;
  right: 5%;
  background-color: #F1F1F1;
  border: 3px solid #285ff5;
  padding: 20px;
  border-radius: 10px;
  z-index: 11;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.storeIcon {
  width: 200px;
}