.CreateEventNextButton {
  display: inline-flex;
  padding: 18px 25px;
  gap: 3px;
  background-color: #000000;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CreateEventNextText {
  font-family: "Wix Madefor Display";
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.CreateEventNextArrow {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  object-fit: contain;
}
