.schedulePage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 84%;
  padding-left: max(calc((100vw - 768px)/2), 8%);
  padding-right: max(calc((100vw - 768px)/2), 8%);
  max-width: 768px;
  padding-bottom: 48px;
  position: relative;
}

.scheduleGuideText {
  font-size: 18px;
  color: #888888;
  margin-top: 24px;
}

.scheduleDayAddBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
  align-self: center;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    width: 30%;
  }
}

.scheduleDayBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

.scheduleDayGap {
  margin-bottom: 24px;
}

.scheduleDayText {
  font-size: 20px;
  color: #121212;
  font-weight: 600;
}

.scheduleTimeText {
  font-size: 18px;
  color: #888888;
  font-weight: 600;
}

.scheduleFutureBlue {
  color: #285ff5;
}

.scheduleActivityBlock {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #888888;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #888888;
  font-weight: 600;
  position: relative;
}

.scheduleActivityBlockFuture {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #285ff5;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #285ff5;
  font-weight: 600;
  position: relative;
}

.deleteActivity {
  position: absolute;
  right: 8px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.addActivityModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: white;
  position: absolute;
  height: 25%;
  bottom: 8%;
  left: 10%;
  right: 10%;
  border-radius: 8px;
  border: #888888 1px solid;

  @media screen and (min-width: 768px) {
    left: 35% !important;
    right: 35% !important;
  }
}

.addActivityInput {
  width: 80%;
  height: 40px;
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 8px;
  font-size: 18px;
}

.addActivityTimeInput {
  width: 80%;
  height: 40px;
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 8px;
  font-size: 18px;
}

.saveActivityButton {
  width: 100px;
  height: 40px;
  border: 1px solid #285ff5;
  border-radius: 8px;
  background-color: #285ff5;
  color: white;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  line-height: 40px;
}

.closeAddActivityButton {
  width: 100px;
  height: 40px;
  border: 1px solid #888888;
  border-radius: 8px;
  background-color: white;
  color: #888888;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  line-height: 40px;
}