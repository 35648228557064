.RSVPHolder {
  display: flex;
  flex-direction: row;
  padding: 24px 65px 24px 24px;
  gap: 32px;
  background-color: #F9F9F9;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
}

.rsvpContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.rsvpCheck {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.rsvpTitle {
  font-weight: 600;
  font-size: 1.1rem;
}

.rsvpSubtitle {
  font-size: 1rem;
  color: #7D7D7D;
  text-align: start;
}

.removeRsvp {
  background-color: #F3F3F3;
  padding: 8px 12px;
  color: #C6C6C6;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-top: 16px;
}