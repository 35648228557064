.EventTileProgress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    z-index: 2;
}

.EventTileFundingBar {
    width: 100%;
    margin-right: 20px;
    height: 8px;
    border-radius: 5px;
    position: relative;
}

.FundingBarOutlineBlack {
    border: 2px solid black;
}

.EventTileFundingBarBack {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;
    opacity: 0.5;
    height: 8px;
    border-radius: 5px;
}

.EventTileFundingBarFilled {
    position: absolute;
    top: 0;
    width: 50%;
    background-color: white;
    opacity: 1;
    height: 8px;
    border-radius: 5px;
    z-index: 3;
}

.EventTileInfoTitleFunding {
    font-weight: 600;
    color: white;
    font-size: 1.25rem;
}

.EventTileFundingBarBackDark {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    height: 8px;
    border-radius: 5px;
}

.FundingBarBackWhite {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;
    height: 8px;
    border-radius: 5px;
}

.EventTileFundingBarFilledDark {
    position: absolute;
    top: 0;
    width: 50%;
    background-color: black;
    opacity: 1;
    height: 8px;
    border-radius: 5px;
    z-index: 3;
}

.EventTileInfoTitleFundingDark {
    font-weight: 600;
    color: black;
    font-size: 1.25rem;
}