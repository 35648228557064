.buttonHolder {
    padding: 15px 30px;
    border-radius: 5px;
    font-family: 'Wix MadeFor Display';
    font-weight: 600;
    font-size: 1.1rem;
    background-color: #F1F1F1;
    color: #797979;
    cursor: pointer;
    flex-direction: row;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.buttonHolderBlack {
    background-color: #000000;
    color: #FFFFFF;
}

.buttonHolderGray {
    background-color: #F1F1F1;
    color: #797979;
}

.buttonArrow {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.arrowFlip {
    transform: rotate(180deg);
}