.scanTicketHolder {
    height: 100%;
    cursor: pointer;
}

.scanTicketTitle {
    color: white;
}

.scanImgHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.scanImg {
    width: 100%;
    height: 50%;
    object-fit: contain;
}