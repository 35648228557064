.ticketHolder {
    height: 100%;
}

.qrTitle {
    display: flex;
    justify-content: space-between;
}

.qrPages {
    color: #767676;
    font-size: 1rem;
}

.qrCode {
    width: 100%;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
}

.qrSlider {
    flex-direction: row;
    display: flex;
    flex: 1;
    height: 80%;
}

.sliderArrow {
    width: 30px;
    height: 30px;
    cursor: pointer;
    align-self: center;
}

.sliderArrowLeft {
    transform: rotate(180deg);
}

.noTicketText {
    margin-top: 30px;
}