.eventCreationLeft {
    display: flex;
    width: 50%;
    padding: 3% 0% 3% 3%;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
}

.eventCreationCentered {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 70%;
}

.eventCreationTitle {
    font-size: 2.375rem;
    font-size-adjust: 0.5;
    font-weight: 600;
    margin-bottom: 5px;
}

.eventCreationSubTitle {
    font-size: 1.15rem;
    line-height: 120%;
    width: 70%;
}

.eventCreationOptionsHolder {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.lowerOption {
    padding-top: 30px;
}

.eventCreationEntry {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

.eventCreationOption {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
}

.eventCreationOptionTitle {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 3px;
}

.eventCreationOptionSubTitle {
    font-size: 1rem;
}

.eventCreationInput {
    width: 90%;
    background-color: #F1F1F1;
    border: none;
    height: 48px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 1rem;
    margin: 10px 0;
    border-radius: 5px;
}

.ant-picker-input > input {
    font-size: 1rem !important;
}

.eventAutocompleteInput {
    width: 90%;
    background-color: #F1F1F1;
    border: none;
    height: 48px;
    padding: 0 !important;
    box-sizing: border-box;
    margin: 10px 0;
    border-radius: 5px;
}

.ant-select-selector {
    width: 100% !important;
    padding: 20px !important;
    margin: none !important;
    background-color: #F1F1F1 !important;
    font-size: 1rem !important;
    border: none !important;
    color: inherit !important;
}

.ant-select-selector:focus-within {
    box-shadow: none !important;
}

.ant-select-selection-search-input {
    font-size: 1rem !important;
    padding: 10px !important;
}

.eventCreationInput::placeholder {
    color: #C6C6C6;
}

.eventCreationInput:focus-within {
    background-color: #F1F1F1 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.eventCreationInput:hover {
    background-color: #F1F1F1;
}

.eventCreationRight {
    display: flex;
    width: 50%;
    padding: 3% 3% 3% 0;
}

.preview-label {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 8px 12px;
    margin: 8px;
    color: #c6c6c6;
    font-weight: 500;
    border-radius: 30px;
}