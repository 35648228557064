.loginPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0px 48px;
    gap: 24px;
}

.loginNavigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.loginSignup {
    font-size: 1rem;
    color: #797979;
    cursor: pointer;
}

.loginForm {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
    gap: 28px;
}

.loginRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1.5px solid #787878;
}

.loginInput {
    width: 100%;
    border: none;
    height: 40px;
    font-size: 1.2rem;
    box-sizing: border-box;
}

.loginAction {
    background-color: #000000;
    color: #FFFFFF;
    padding: 21px 27px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    margin: 24px 0px;
}

.passwordRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.showPassword img {
    object-fit: contain;
    width: 20px;
    cursor: pointer;
}

.loginInput::placeholder {
    color: #9d9d9d;
    font-size: 1rem;
}