.newWidgetHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
}

.newWidgetTitle {
    font-family: 'Wix Madefor Display';
    font-size: 1.2rem;
    font-weight: 600;
    text-align: start;
    color: #ADADAD;
}